<template>
    <v-container>
        <new-payment
        v-if="showNewModal"
        :currency="payment.currency_id ? payment.currency.currency : getCurrency"
        :payments="payments"
        :pending="payment.totalCreditCollected"
        :show-all="false"
        @addPayment="createPayment"/>
        <v-row>
            <v-col
                class="py-0"
                cols="12"
            >
                <app-data-table
                    :title="showTitle ? titleTable : ''"
                    :select-data="selectData"
                    csv-filename="Categories"
                    :headers="getTableColumns"
                    :items="creditsSales"
                    :second-date-data="new Date(new Date().getTime() + 31 * 24 * 60 * 60 * 1000)"
                    :first-date-data="new Date(new Date().getTime() - 31 * 24 * 60 * 60 * 1000)"
                    :manager="'client'"
                    :sort-by="['firstName']"
                    :sort-desc="[false, true]"
                    :viewShowFilterDate="true"
                    multi-sort
                    :options="vBindOption"
                    :multi-select=true
                    :viewShowButton="false"
                    :viewNewButton="false"
                    :viewPayButton="true"
                    :viewEditButton="false"
                    :viewDeleteButton="false"
                    @reload-data="loadInitData($event)"
                    @new-pay="showNewPay($event)"
                >
                    <template v-slot:[`item.client`]="{ item }">
                        <v-avatar>
                            <v-img
                                :src="item.avatar ||`/assets/avatar/avatar-undefined.jpg`"
                            />
                        </v-avatar>
                        {{ item.client ? item.client.first_name : '---'}}
                         {{ item.client ? item.client.last_name ? item.client.last_name : '': ''}}
                    </template>
                    <template v-slot:[`item.pays`]="{ item }">
                        {{ new Date(getCreditPay(item).mora).toLocaleDateString() }}
                    </template>
                    <template v-slot:[`item.lastName`]="{ item }">
                      {{ parseFloat(getCreditPay(item).cant).toFixed(2) }} {{getCreditPay(item).currency_id ? getCreditPay(item).currency.currency : getCurrency }}
                    </template>
                    <template v-slot:[`item.firstName`]="{ item }">
                         {{ getCreditPay(item).cantMora ? parseFloat(getCreditPay(item).cantMora).toFixed(2) : '0.00'}} {{getCreditPay(item).currency_id ? getCreditPay(item).currency.currency : getCurrency }}
                    </template>
                    <template v-slot:[`item.totalCreditPay`]="{ item }">
                        {{ parseFloat(getCreditPay(item).totalCreditPay).toFixed(2) }} {{getCreditPay(item).currency_id ? getCreditPay(item).currency.currency : user.getCurrency}}
                    </template>
                    <template v-slot:[`item.totalCreditCollected`]="{ item }">
                      {{ parseFloat(getCreditPay(item).totalCreditCollected).toFixed(2) }} {{ getCreditPay(item).currency_id ? getCreditPay(item).currency.currency : getCurrency }}
                    </template>
                    <template
                        v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded}"
                    ><v-btn
                        v-if="getCreditPay(item).pays.length > 0"
                        color="primary"
                        fab
                        x-small
                        dark
                        @click="expand(!isExpanded)"
                    >
                        <v-icon v-if="isExpanded">
                            mdi-chevron-up
                        </v-icon>
                        <v-icon v-else>
                            mdi-chevron-down
                        </v-icon>
                    </v-btn>
                    </template>
                    <template v-slot:expanded-item="{ headers, item }">
                        <td
                            :colspan="headers.length"
                            style="padding: 0 0 0 0"
                        >
                            <v-simple-table dense>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left">
                                            {{ $vuetify.lang.t('$vuetify.payment.name') }}</th>
                                        <th class="text-left">
                                            {{ $vuetify.lang.t("$vuetify.payment.cant_pay") }}</th>
                                        <th class="text-left">
                                            {{ $vuetify.lang.t("$vuetify.payment.cant_charge") }}</th>

                                        <th class="text-left">
                                            {{ $vuetify.lang.t("$vuetify.payment.cant_back") }}
                                        </th>

                                        <th class="text-left">
                                            {{ $vuetify.lang.t('$vuetify.actions.actions') }}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="pay in getCreditPay(item).pays"
                                        :key="pay.id">
                                        <td>{{ $vuetify.lang.t( '$vuetify.payment.' + pay.method) }}</td>
                                        <td>{{ parseFloat(pay.cant).toFixed(2) }}</td>
                                        <td>{{pay.cant ? parseFloat(pay.cant + pay.cant_back).toFixed(2) : '0.00'}}</td>
                                        <td>{{pay.cant_back ? parseFloat(pay.cant_back).toFixed(2) : '0.00'}}</td>
                                        <td>
                                            <v-icon
                                                small
                                                @click="cancelPay(pay.id)"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </td>
                    </template>
                </app-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import NewPayment from '../../pay/NewPay'

export default {
  name: 'AccountToPay',
  components: { NewPayment },
  data () {
    return {
      showPay: false,
      payment: null,
      pendig: 0.00,
      payments: [],
      vBindOption: {
        itemKey: 'no_facture',
        singleExpand: false,
        showExpand: true
      },
      titleTable: this.$vuetify.lang.t('$vuetify.menu.accounts_to_receivable'),
      selectData: []
    }
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    this.loadInitData(null)
  },
  computed: {
    ...mapState('sale', ['creditsSales']),
    ...mapState('payment', ['showNewModal']),
    ...mapGetters('auth', ['user']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.tax.noFacture'),
          value: 'no_facture',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'client',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.payment.pay_before'),
          value: 'pays',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.payment.cant_charge'),
          value: 'lastName',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.payment.pay_delay'),
          value: 'firstName',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pay.total_pay'),
          value: 'totalCreditPay',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pay.total_to_pay'),
          value: 'totalCreditCollected',
          select_filter: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    ...mapActions('sale', ['getCreditSalesToPay']),
    ...mapActions('payment', ['toogleNewModal', 'createPayPayment', 'deletePay']),
    async loadInitData (filter) {
      await this.getCreditSalesToPay(filter)
    },
    getCreditPay (item) {
      return item.pays.filter(py => py.bank_payment.payment.name === 'credit')[0]
    },
    showNewPay (sale) {
      this.payment = sale.pays.filter(p => p.bank_payment.payment.method === 'credit')[0]
      this.payments = sale.payments
      this.toogleNewModal(true)
    },
    async cancelPay (id) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.pay.pay')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.delete'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.value) this.deletePay({ id: id, type: 'sale' })
        })
    },
    createPayment (pay) {
      pay.pay_sale = this.payment
      this.toogleNewModal(false)
      this.createPayPayment({ pay: pay, type: 'sale' }).then(() => {
        this.payment = null
        this.payments = []
      })
    }
  }
}
</script>

<style scoped>

</style>
